<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="120px" :model="formLabelAlign">
        <el-form-item
          label="模板类型："
          prop="type"
          :rules="[{ required: true, message: '请选择模板类型' }]"
        >
          <el-select
            class="form-inp"
            v-model="formLabelAlign.type"
            placeholder="模板类型："
          >
            <el-option
              v-for="item in status_list"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="模板名称："
          prop="flowname"
          :rules="[{ required: true, message: '请选择模板类型' }]"
        >
          <el-input
            placeholder="请输入模板名称"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.flowname"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { flowsaveinfo, flowsave } from "../../assets/request/api";
import Bepartment from "../../components/Bepartment";
export default {
  name: "student_redacy",
  components: {
    Bepartment,
  },
  props: {
    dialog_show: {
      type: Boolean,
    },
  },
  data() {
    return {
      type_name: "保存",
      //要修改的id
      id: "",
      options: [],
      status_list: [],
      formLabelAlign: {
        type: "",
        flowname: "",
      },
    };
  },
  methods: {
    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      flowsaveinfo(froms).then((res) => {
        let { info, type_list } = res.data;
        this.status_list = type_list;
        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let { flowname, flowcode } = info;
          formLabelAlign.flowname = flowname;
          formLabelAlign.type = flowcode;
        }
      });
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { formLabelAlign, id } = this;
          if (id) {
            formLabelAlign.id = id;
          }
          flowsave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.$emit("update:refbool", false);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
</style>
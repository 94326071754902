<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form label-width="120px" :model="formLabelAlign" class="from">
            <el-form-item label="模板名称：">
              <el-input
                placeholder="请输入模板名称"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.truename"
              ></el-input>
            </el-form-item>
 
            <el-form-item label="模板类型：">
              <el-select
                v-model="formLabelAlign.status"
                placeholder="请选择模板类型态"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button
                @click="way_tableData(true)"
                type="primary"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button @click="on_reset" icon="el-icon-refresh-left"
                >重置</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个模板</span>
            <span class="span2">已选择 {{ more_List.length }} 个模板</span>
            <i class="span_i el-icon-refresh" @click="way_tableData()"
              >数据更新</i
            >
          </p>
          <div>
            <el-button
              type="primary"
              @click="on_pop_addplus()"
              icon="el-icon-plus"
              >新建</el-button
            >
            <el-button @click="on_deleteList" icon="el-icon-close"
              >删除</el-button
            >
            <!-- <el-button @click="on_export">导出</el-button> -->
          </div>
        </div>
        <div>
          <el-table
            highlight-current-row
            @current-change="way_singleselect"
            @selection-change="way_moreselect"
            :data="table_List"
            :header-cell-style="{ background: '#f8f8f9' }"
            style="flex: 1"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="flowname" label="模板名称"></el-table-column>
            <el-table-column prop="flowcode" label="模板类型"></el-table-column>
            <el-table-column prop="address" label="操作">
              <template slot-scope="scope">
                <el-link
                  class="list_sty"
                  type="primary"
                  @click="on_pop_addplus(scope.row.id)"
                  >修改</el-link
                >
                <el-link
                  @click="on_pop_flow(scope.row.id)"
                  class="list_sty"
                  type="primary"
                  >绘制流程图</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination
            @current-change="way_page"
            :page-size="page_size"
            layout="prev, pager, next, jumper"
            :total="total"
            :current-page="page"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 新建修改弹窗 -->
    <el-dialog :title="title" show-close :visible.sync="dialog_show" center>
      <processredact
        v-if="dialog_show"
        ref="processredact"
        :refbool.sync="dialog_show"
        @way_tableData="way_tableData"
      />
    </el-dialog>
    <!-- 流程图弹窗 -->
    <el-dialog title="流程图" show-close :visible.sync="flow_show" center>
      <flowchart
        v-if="flow_show"
        ref="flowchart"
        :refbool.sync="flow_show"
        @way_tableData="way_tableData"
      />
    </el-dialog>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import processredact from "../../components/view_redact/process_redact";
import flowchart from "../../components/flow_chart";
import { flowindex, flowflowDelete } from "../../assets/request/api";
export default {
  name: "process_management",
  components: { processredact, flowchart },
  mixins: [table_minin],
  data() {
    return {
      is_extend: true,
      formLabelAlign: {
        truename: "",
        status: "",
      },
      enable_list: [],
      dialog_show: false,
      flow_show: false,
      title: "",
    };
  },
  created() {
    this.url = flowindex;
    this.delete_Url = flowflowDelete;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "type_list";
    this.way_tableData();
  },
  methods: {
    way_extend(data) {
      this.enable_list = data.enable_list;
    },
    on_pop_addplus(id) {
      this.dialog_show = true;
      this.title = id ? "修改" : "新增";
      this.$nextTick(function () {
        this.$refs.processredact.way_show(id);
      });
    },
    on_pop_flow(id) {
      this.flow_show = true;
      this.$nextTick(function () {
        this.$refs.flowchart.way_show(id);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 200px;
}
.paging {
  text-align: center;
  padding: 10px;
}
.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  button {
    margin-right: 10px;
  }
  p {
    display: flex;
    align-items: center;
  }
}
.span1 {
  font-size: 14px;
  color: #000000;
}
.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}
.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}
.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.list_sty {
  margin-right: 10px;
}
</style>
